<template>
  <v-card class="mb-2">
    <v-card-title>Person</v-card-title>
    <v-card-text>
      <p>Stellt eine Person mit Avatar und Namen dar</p>
      <v-row>
        <v-col
          ><PersonItem
            v-if="person"
            :value="person"
            :clickable="clickable"
            :nolink="nolink"
            :nodetails="nodetails"
            :description="description"
            @click="clicked"
        /></v-col>
        <v-col
          ><v-text-field
            type="number"
            label="personId"
            outlined
            v-model="id"
          ></v-text-field>
          <v-text-field
            type="text"
            label="description"
            outlined
            v-model="description"
          ></v-text-field>
          <v-checkbox
            v-model="clickable"
            :label="`clickable: ${clickable.toString()}`"
            persistent-hint
            hint="Item ist clickbar: Standard-to-Link und click-Event. (wird in PersonPicker verwendet)"
          ></v-checkbox>
          <v-checkbox
            v-model="nolink"
            :label="`nolink: ${nolink.toString()}`"
            persistent-hint
            hint="Item soll kein Link haben. (kann trotzdem klickbar sein, um ein Event zu generieren)"
          ></v-checkbox>
          <v-checkbox
            v-model="nodetails"
            :label="`nodetails: ${nodetails.toString()}`"
            persistent-hint
            hint="Item zeigt die drei Punkte nicht an. (ganzes Item ist klickbar)"
          ></v-checkbox>
        </v-col>
      </v-row>

      <code
        >&lt;Person :value="person" :clickable="{{ clickable }}" :nolink="{{
          nolink
        }}" :nodetails="{{ nodetails }}" :description="{{ description }}"
        @click="clicked" /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import PersonItem from "@/components/PersonItem";
export default {
  name: "PersonDemo",
  components: { PersonItem },
  data() {
    return {
      id: 162,
      person: null,
      clickable: false,
      nolink: false,
      nodetails: false,
      description: "",
    };
  },
  methods: {
    async getData() {
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
    },
    clicked($event) {
      alert("Click-Event: guck mal in der Konsole...");
      console.log($event);
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>
