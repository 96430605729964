<template>
  <v-container>
    <p>
      Hier sollen die zur Verfügung stehenden Komponenten dokumentiert werden
    </p>
    <StaticTextDemo v-if="visible('StaticText')" />
    <LookupValueDemo v-if="visible('LookupValue')" />
    <PersonDemo v-if="visible('Person')" />
    <PersonNameDemo v-if="visible('PersonName')" />
    <PortraitImageDemo v-if="visible('PortraitImage')" />
    <PostalAddressDemo v-if="visible('PostalAddress')" />
    <CourseDemo v-if="visible('Course')" />
    <SchoolClassDemo v-if="visible('SchoolClass')" />
    <DateDemo v-if="visible('Date')" />
    <DivisionTabsDemo v-if="visible('DivisionTabs')" />
    <RowItemDemo v-if="visible('RowItem')" />
  </v-container>
</template>
<script>
import CourseDemo from "@/components/demos/CourseDemo";
import DateDemo from "@/components/demos/DateDemo";
import DivisionTabsDemo from "@/components/demos/DivisionTabsDemo";
import LookupValueDemo from "@/components/demos/LookupValueDemo";
import PersonDemo from "@/components/demos/PersonDemo";
import PersonNameDemo from "@/components/demos/PersonNameDemo";
import PortraitImageDemo from "@/components/demos/PortraitImageDemo";
import PostalAddressDemo from "@/components/demos/PostalAddressDemo";
import RowItemDemo from "@/components/demos/RowItemDemo";
import SchoolClassDemo from "@/components/demos/SchoolClassDemo";
import StaticTextDemo from "@/components/demos/StaticTextDemo";
export default {
  name: "DocumentationComponents",
  components: {
    CourseDemo,
    DateDemo,
    DivisionTabsDemo,
    LookupValueDemo,
    PersonDemo,
    PersonNameDemo,
    PortraitImageDemo,
    PostalAddressDemo,
    RowItemDemo,
    SchoolClassDemo,
    StaticTextDemo,
  },
  props: ["search"],
  methods: {
    visible(name) {
      return (
        !this.search || name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>
