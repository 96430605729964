<template>
  <v-card class="mb-2">
    <v-card-title>DivisionTabs</v-card-title>
    <v-card-text>
      <p>Tab-Auswahl der Abteilungen</p>
      <v-row>
        <v-col><DivisionTabs :all="all" /></v-col>
        <v-col>
          <v-checkbox
            v-model="all"
            :label="`all: ${all.toString()}`"
            persistent-hint
            hint="Tab für alle anzeigen"
          ></v-checkbox>
        </v-col>
      </v-row>

      <code>&lt;DivisionTabs :all="{{ all }}" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import DivisionTabs from '@/components/DivisionTabs';
export default {
  name: 'DivisionTabsDemo',
  components: { DivisionTabs },
  data() {
    return {
      all: false,
    };
  },
};
</script>