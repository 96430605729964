<template>
  <v-card class="mb-2">
    <v-card-title>StaticText</v-card-title>
    <v-card-text>
      <p>Stellt einen Text mit optionalem Tooltip und Platzhalter dar.</p>
      <v-row>
        <v-col>
          <StaticText
            :value="value"
            :tooltip="tooltip"
            :placeholder="placeholder"
          />
        </v-col>
        <v-col
          ><v-text-field
            type="text"
            label="value"
            outlined
            v-model="value"
          ></v-text-field>
          <v-text-field
            type="text"
            label="tooltip"
            outlined
            v-model="tooltip"
          ></v-text-field>
          <v-text-field
            type="text"
            label="placeholder"
            outlined
            v-model="placeholder"
          ></v-text-field>
        </v-col>
      </v-row>

      <code
        >&lt;StaticText :value="value" :tooltip="tooltip"
        :placeholder="placeholder"/&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import StaticText from 'common/components/StaticText.vue';
export default {
  name: 'StaticTextDemo',
  components: { StaticText },
  data() {
    return {
      value: 'Ein Text',
      tooltip: '',
      placeholder: '—',
    };
  },
};
</script>
