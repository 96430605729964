<template>
  <v-card class="mb-2">
    <v-card-title>SchoolClass</v-card-title>
    <v-card-text>
      <p>Stellt einen Klasse als v-list-item dar</p>
      <v-row>
        <v-col
          ><SchoolClass
            v-if="schoolClass"
            :value="schoolClass"
            :clickable="clickable"
            :nolink="nolink"
            :nodetails="nodetails"
            @click="clicked"
        /></v-col>
        <v-col>
          <v-slider
            v-if="schoolClasses.length > 0"
            v-model="index"
            :max="schoolClasses.length - 1"
            :min="0"
          ></v-slider>
          <v-checkbox
            v-model="clickable"
            :label="`clickable: ${clickable.toString()}`"
            persistent-hint
            hint="Item ist clickbar: Standart-to-Link und click-Event. (wird in PersonPicker verwendet)"
          ></v-checkbox>
          <v-checkbox
            v-model="nolink"
            :label="`nolink: ${nolink.toString()}`"
            persistent-hint
            hint="Item soll kein Link haben. (kann trotzdem klickbar sein, um ein Event zu generieren)"
          ></v-checkbox>
          <v-checkbox
            v-model="nodetails"
            :label="`nodetails: ${nodetails.toString()}`"
            persistent-hint
            hint="Item zeigt die drei Punkte nicht an. (ganzes Item ist klickbar)"
          ></v-checkbox>
        </v-col>
      </v-row>
      <code
        >&lt;SchoolClass :value="schoolClass" :clickable="{{ clickable }}"
        :nolink="{{ nolink }}" :nodetails="{{ nodetails }}" @click="clicked"
        /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import SchoolClass from '@/components/SchoolClass';
export default {
  name: 'SchoolClassDemo',
  components: { SchoolClass },
  data() {
    return {
      index: 0,
      schoolClasses: {},
      schoolClass: null,
      clickable: false,
      nolink: false,
      nodetails: false,
    };
  },
  watch: {
    index() {
      this.schoolClass = this.schoolClasses[this.index];
    },
  },
  async created() {
    this.schoolClasses = await this.apiList({ resource: 'schoolclass/schoolclass' });
    this.schoolClass = this.schoolClasses[this.index];
  },
  methods: {
    clicked($event) {
      alert('Click-Event: guck mal in der Konsole...');
      console.log($event);
    },
  },
};
</script>
