<template>
  <v-card class="mb-2">
    <v-card-title>Date</v-card-title>
    <v-card-text>
      <p>Stellt ein Json-Datum in normalen Format dar.</p>
      <v-row>
        <v-col><DateValue :value="date" /></v-col>
        <v-text-field v-model="date"></v-text-field>
      </v-row>

      <code>&lt;Date value="{{ date }}" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import DateValue from 'common/components/DateValue.vue';

export default {
  name: 'DateDemo',
  components: { DateValue },
  data() {
    return {
      date: '2021-06-29',
    };
  },
};
</script>
