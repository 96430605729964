<template>
  <v-card class="mb-2">
    <v-card-title>LookupValue</v-card-title>
    <v-card-text>
      <p>Stellt einen LookupValue dar.</p>
      <v-row>
        <v-col>
          <LookupValue :value="value" />
        </v-col>
        <v-col> </v-col>
      </v-row>

      <code>&lt;LookupValue :value="value"/&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import LookupValue from 'common/components/LookupValue.vue';
export default {
  name: 'LookupValueDemo',
  components: { LookupValue },
  data() {
    return {
      value: {
        id: 1,
        description: 'Geistes- und Humanwissenschaften',
        code: 'GH',
      },
    };
  },
};
</script>
