<template>
  <v-card class="mb-2">
    <v-card-title>RowItem</v-card-title>
    <v-card-text>
      <p>
        Für horizontale Aufzählungen von Eigenschaften (title) mit Wert
        (content)
      </p>
      <v-row>
        <v-col><RowItem title="title">content</RowItem></v-col>
      </v-row>

      <code>&lt;RowItem title="title"&gt;content&lt;/RowItem&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import RowItem from '@/components/RowItem';

export default {
  name: 'RowItemDemo',
  components: { RowItem },
};
</script>
